

<template>
    <Layout>
        <PageHeader :title="title" :items="[]" />
    <loading-vue v-if="load"></loading-vue>
    
        <div class="row g-2" v-else>
            <div class="col-md-5 mx-auto">

    
              

                
     <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">Quick Deposit</h4>
          
          </div>
          <!-- end card header -->
          <div class="card-body">
            
            <div class="live-preview">
              <form action="javascript:void(0);">
                  <!-- Info Alert -->
    <b-alert show variant="info">
    <strong> Note: </strong> Money deposited will be automatically credited to your wallet (1% fee). 
    </b-alert>
                <div class="mb-3">
                  <label for="amount" class="form-label"
                    >Amount</label
                  >
                  <input
                    type="number"
                    
                    v-model="amount"
                    class="form-control"
                    id="amount"
                    placeholder="Amount"
                  />
                </div>
                
                
                <div class="text-end">
               
       <button type="submit" class="btn btn-primary mx-2" @click="payWithMonnify">Monnify</button>
       <button type="submit" class="btn btn-primary mx-2" @click="makePayment">
                    
                    Flutterwave
                  </button>

                </div>
              </form>
            </div>
          
          </div>
        </div>
      </div>
   
    </div>
     
                
            </div>
            <!-- end col -->
        </div>
        <!--end row-->
    
    
    </Layout>
    
</template>






<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";

// import axios from 'axios';
import Swal from "sweetalert2";
import LoadingVue from "./components/Loading.vue";

import {useFlutterwave} from "flutterwave-vue3"
export default {
    page: {
        title: "Quick Deposit",
        meta: [{ name: "description", content: appConfig.description }],
    },
    data() {
        return {
         
            title: "Quick Deposit",
             amount: null,
            load: false,
            items: [],
        };
    },
    name: "Widgets",
    components: {
        Layout,

        PageHeader,
       
        LoadingVue,
  
    },
    
    created() {
       var scripts = ["https://sdk.monnify.com/plugin/monnify.js",
    
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });

    },
    computed: {



      calculate(){




      return (this.amount * (1/100)) + this.amount;

      },




      user(){
         return this.$store.getters['getuser'];
      },
      ref(){
        return Date.now();
      },
       payRef(){
          let _ref = "";
          const alphanumeric = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

          for( let i=0; i < 10; i++ )
            _ref += alphanumeric.charAt(Math.floor(Math.random() * alphanumeric.length));

          return _ref;
        }
    },
    methods: {
     payWithMonnify() {
      window.MonnifySDK.initialize({
			amount: this.calculate,
			currency: "NGN",
			reference: '' + Math.floor((Math.random() * 1000000000) + 1),
			customerName: this.user.name,
			customerEmail: this.user.email,
			apiKey:  process.env.VUE_APP_MONNIFYAPIKEY,
			contractCode:  process.env.VUE_APP_MONNIFYCONTRACTCODE,
			paymentDescription: "Wallet Deposit",
			isTestMode: true,
			paymentMethods: ["CARD", "ACCOUNT_TRANSFER"],
      onComplete: function(response){
      console.log(response);
      if (response.status == 'SUCCESS') {
      Swal.fire("Payment Received!", "Your Payment has been processed successfully", "success");
      } else {
      Swal.fire("Error!", "No Payment Request was received", "error");
        
      }
    
			},
			onClose: function(data){
			console.log(data);
      // Swal.fire("Error!", "No Payment Request was received", "error");
			}
		
		});
	},
      channel(){
               return ["card", "bank", "ussd", "qr", "bank_transfer"];
      },
      onCancelledPayment(){

           Swal.fire("Error!", "No Payment Request was received", "error");
           
 
      },
         onSuccessfulPayment(){

        Swal.fire("Payment Received!", "Your Payment has successfully being processed", "success");
 
 
      },
    makePayment() {



        if (this.amount >= 200) {


//this will launch Fluterwave payment modal
useFlutterwave({
    amount: this.calculate,//amount
    callback: function(data) {
          // specified callback function
          document.getElementsByName("checkout").remove();
          document.getElementsByClassName("checkout-modal--loaded").remove();
          console.log(data)
          if(data.status == 'successful'){

           Swal.fire("Payment Received!", "Your Payment has successfully being processed", "success");
           

          }else {

            Swal.fire("Error!", "No Payment Request was received", "error");
           

          }
        },
    country: "NG",
    currency: "NGN",
   customer: {
          email: this.user.email,
          phonenumber: this.user.phone,
          name: this.user.name,
        },
    customizations: {description: "Deposit on Gopay", logo: "", title: "Gopay"},
    
    onclose: void {

    },
    payment_options:  "card",
    public_key: "FLWPUBK-74a7137b5bd3d120fb26786cb22725b5-X",
    redirect_url: undefined,
    tx_ref: this.ref,
  })


        }else {

           Swal.fire("Error!", "The minimum amount to deposit is ngn 200", "error");

        }
      
    }
  }

};
</script>
<style scoped>
.active {
    border: 2px solid green;
}
</style>